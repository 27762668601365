import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InventoryCategoryDto } from '@mrsoftebs/data';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from '../../../../../apps/ebs/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InventoryCategoryService {
  url = environment.apiUrl + 'reference/category/';
  constructor(private http: HttpClient) {}

  getInventoryCategory() {
    return this.http.get<InventoryCategoryDto[]>(this.url);
  }
  createInventoryCategory(category: InventoryCategoryDto[]) {
    return this.http.post(this.url, category);
  }

  updateInventoryCategory(category: InventoryCategoryDto[]) {
    return this.http.post(this.url + 'update', category);
  }

  deleteInventoryCategory(ids: string[]) {
    return this.http.post(this.url + 'remove', ids);
  }
}
