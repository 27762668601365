import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestBreakDowns } from '@mrsoftebs/data';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;
const route = 'requestbreakdowns/';
@Injectable({
  providedIn: 'root'
})
export class RequestBreakDownService {


  constructor(private http: HttpClient) {}

  getRequisitionById(id): Observable<RequestBreakDowns[]> {
    return this.http.get<RequestBreakDowns[]>(api + route + id);
  }
  getAllRequestBreakDown(data?): Observable<RequestBreakDowns[]> {
    return this.http.get<RequestBreakDowns[]>(api + route);
  }


  createRequestBreakDown(requestBreakDowns) {
    console.log("Data to send ", requestBreakDowns);
    return this.http.post(api + route, requestBreakDowns);
  }
  updateRequestBreakDown(data) {
    return this.http.post(api + route+ 'update', data);
  }

  deleteRequestBreakDown(id: any) {
    
    return this.http.delete(api + route + id); 
  }

}




