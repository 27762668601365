import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ManufacturerDto } from '@mrsoftebs/data';
import { environment } from '../../../../../apps/ebs/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ManufacturerService {
  url = environment.apiUrl + 'reference/manufacturer/';
  constructor(private http: HttpClient) {}

  getManufacturer() {
    return this.http.get<ManufacturerDto[]>(this.url);
  }
  createManufacturer(status: ManufacturerDto[]) {
    return this.http.post(this.url, status);
  }

  updateManufacturer(status: ManufacturerDto[]) {
    return this.http.post(this.url + 'update', status);
  }

  deleteManufacturer(ids: string[]) {
    return this.http.post(this.url + 'remove', ids);
  }
}
