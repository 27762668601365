import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaffStatus } from '@mrsoftebs/data';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;
const route = 'staffstatus/';

@Injectable({
  providedIn: 'root',
})
export class StaffstatusService {
  constructor(private http: HttpClient) {}

  getStaffStatusById(id): Observable<StaffStatus> {
    return this.http.get<StaffStatus>(api + route + id);
  }
  getAllStaffStatus(data?): Observable<StaffStatus[]> {
    return this.http.get<StaffStatus[]>(api + route);
  }

  createStaffStatus(staffStatuss: StaffStatus[]) {
    //console.log('update create: ', relationshiptypes);
    return this.http.post(api + route, staffStatuss);
  }

  updateStaffStatus(data) {
    return this.http.post(api + 'staffstatus/update', data);
  }

  deleteStaffStatus(id: any) {
    return this.http.post(api + route + 'delete', id);
  }
}
