import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TravelType } from '@mrsoftebs/data';
import { environment } from 'apps/ebs/src/environments/environment';

const url = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class TravelTypeService {
  constructor(private http: HttpClient) {}

  getTravelTypes() {
    return this.http.get<TravelType[]>(url + 'travel-type');
  }
  createTravelType(data: TravelType[]) {
    return this.http.post(url + 'travel-type/create', data);
  }

  updateTravelType(data: TravelType[]) {
    return this.http.post(url + 'travel-type/update', data);
  }

  findOneType(id) {
    return this.http.get(url + 'travel-type/find' + id);
  }

  deleteTravelType(ids: any) {
    // console.log('ids', ids);

    // const httpOptions = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    //   body: ids,
    // };

    // let params = new HttpParams();
    // params = params.append('travel-type', ids.join(', '));
    return this.http.post(url + 'travel-type/delete', ids);
  }
}
