import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Criterial } from '@mrsoftebs/data';
import { Observable } from 'rxjs';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;
const route = 'criterial/';

@Injectable({
  providedIn: 'root',
})
export class CriterialService {
  constructor(private http: HttpClient) {}

  getCriterialById(id): Observable<Criterial> {
    return this.http.get<Criterial>(api + route + id);
  }
  getAllCriterial(data?): Observable<Criterial[]> {
    return this.http.get<Criterial[]>(api + route);
  }

  createCriterial(criterial: Criterial[]) {
    return this.http.post(api + route, criterial);
  }

  updateCriterial(data) {
    return this.http.post(api + 'criterial/update', data);
  }

  deleteCriterial(id: any) {
   
    return this.http.post(api + route + 'delete', id);
  }
}
