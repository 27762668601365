import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/ebs/src/environments/environment';
import { Level } from '@mrsoftebs/data';
import { Observable } from 'rxjs';

const apiUrl = environment.apiUrl;
const route = 'level/';

@Injectable({ providedIn: 'root' })
export class LevelService {
  constructor(private http: HttpClient) {}
  findAll(): Observable<Level[]> {
    return this.http.get<Level[]>(apiUrl + route);
  }

  updateMany(data) {
    return this.http.post(apiUrl + route, data);
  }
  getLevelByGradeId(gradeId: string) {
    return this.http.get<Level[]>(apiUrl + 'level/gradeId/' + gradeId);
  }
  findOne(id: string) {
    console.log('LevelId: ', id);
    return id;
    // return this.http.get(apiUrl + route + id);
  }
}
