import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Position } from '@mrsoftebs/data';
import { Observable } from 'rxjs';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;
const route = 'position/';

@Injectable({
  providedIn: 'root',
})
export class PositionService {
  constructor(private http: HttpClient) {}

  getPositionById(id): Observable<Position> {
    return this.http.get<Position>(api + route + id);
  }
  getAllPosition(data?): Observable<Position[]> {
    return this.http.get<Position[]>(api + route);
  }
  updateunit() {
    return this.http.get(api + route + 'updateunit/all');
  }
  createPosition(position: Position[]) {
    return this.http.post(api + route, position);
  }

  updatePosition(data) {
    return this.http.post(api + 'position/update', data);
  }

  deletePosition(id: any) {
    return this.http.post(api + route + 'delete', id);
  }

  getPositionByJobId(jobTitleId: string) {
    console.log('Job Title Id ', jobTitleId);
    return this.http.get<Position[]>(api + route + 'jobtitle/' + jobTitleId);
  }
}
