import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TendrStatus } from '@mrsoftebs/data';
import { environment } from 'apps/ebs/src/environments/environment';

const url = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class TenderStatusService {
  constructor(private http: HttpClient) {}

  getStatus() {
    return this.http.get<TendrStatus[]>(url + 'tenstatus');
  }
  createStatus(status: TendrStatus[]) {
    return this.http.post(url + 'tenstatus/create', status);
  }

  updateStatus(status: TendrStatus[]) {
    return this.http.post(url + 'tenstatus/update', status);
  }

  deleteStatus(ids: any) {
   
    return this.http.post(url + 'tenstatus/delete', ids);
  }
}
