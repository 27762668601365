import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ActionStatus } from '@mrsoftebs/data';
import { Observable } from 'rxjs';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;
const route = 'actionstatus/';

@Injectable({
  providedIn: 'root',
})
export class ActionStatusService {
  constructor(private http: HttpClient) {}

  getActionStatusById(id): Observable<ActionStatus> {
    return this.http.get<ActionStatus>(api + route + id);
  }
  getAllActionStatus(data?): Observable<ActionStatus[]> {
    console.log('data: ', data);
    return this.http.get<ActionStatus[]>(api + route);
  }

  createActionStatus(actionstatus: ActionStatus[]) {
    return this.http.post(api + route, actionstatus);
  }

  updateActionStatus(data) {
    return this.http.post(api + 'actionstatus/update', data);
  }

  deleteActionStatus(id: any) {
    return this.http.post(api + route + 'delete', id);
  }
}
