import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TravelAllowance } from '@mrsoftebs/data';
import { environment } from 'apps/ebs/src/environments/environment';

const url = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class TravelAllowanceService {
  constructor(private http: HttpClient) {}

  getTravelAllowances() {
    return this.http.get<TravelAllowance[]>(url + 'travel-allowance');
  }
  createTravelAllowance(allowance: TravelAllowance[]) {
    return this.http.post(url + 'travel-allowance/create', allowance);
  }

  updateTravelAllowance(allowance: TravelAllowance[]) {
    return this.http.post(url + 'travel-allowance/update', allowance);
  }

  findOneAllowance(id) {
    return this.http.get(url + 'travel-allowance/find' + id);
  }

  deleteTravelAllowance(ids: any) {
    // console.log('ids', ids);

    // const httpOptions = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    //   body: ids,
    // };

    // let params = new HttpParams();
    // params = params.append('travel-allowance', ids.join(', '));
    return this.http.post(url + 'travel-allowance/delete', ids);
  }
}
