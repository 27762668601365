import { Injectable } from '@angular/core';
import { Grade } from '@mrsoftebs/data';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/ebs/src/environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.apiUrl;
const route = 'grade/';

@Injectable({ providedIn: 'root' })
export class GradeService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<Grade[]> {
    return this.http.get<Grade[]>(apiUrl + route);
  }
  updateMany(data) {
    return this.http.post(apiUrl + route, data);
  }
  findOne(id: string) {
    return this.http.get(apiUrl + route + id);
  }
}
