import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayName',
})
export class DisplayNamePipe implements PipeTransform {
  transform(
    value: string,
    datas: unknown[],
    fieldName: string,
    displayName: string
  ): string | null {
    const data = datas.find((data) => data[fieldName] === value);
    // console.log('data pipe', datas, data, fieldName, displayName);
    return data ? data[displayName] : value;
  }
}
