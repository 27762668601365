import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Approval } from '@mrsoftebs/data';
import { Observable } from 'rxjs';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;
const route = 'bookingapproval/';

@Injectable({
  providedIn: 'root',
})
export class ApprovalService {
  constructor(private http: HttpClient) {}

  getApprovalById(id): Observable<Approval> {
    return this.http.get<Approval>(api + route + id);
  }
  getAllApproval(data?): Observable<Approval[]> {
    return this.http.get<Approval[]>(api + route);
  }

  createApproval(approval: Approval[]) {
    return this.http.post(api + route, approval);
  }

  updateApproval(data) {
    return this.http.post(api + route + 'update', data);
  }

  deleteApproval(id: any) {
    return this.http.post(api + route + 'delete', id);
  }
}
