import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IncidentType } from '@mrsoftebs/data';
import { environment } from 'apps/ebs/src/environments/environment';
import { Observable } from 'rxjs';
const apiUrl = environment.apiUrl;
const route = 'incidenttype/';

@Injectable({
  providedIn: 'root',
})
export class IndidentTypeService {
  constructor(private http: HttpClient) {}

  getIncidentTypeById(id): Observable<IncidentType> {
    return this.http.get<IncidentType>(apiUrl + route + id);
  }

  getAllIncidentType(data?): Observable<IncidentType[]> {
    return this.http.get<IncidentType[]>(apiUrl + route);
  }

  createIncidentType(title: IncidentType[]) {
    return this.http.post(apiUrl + route, title);
  }

  updateIncidentType(data) {
    return this.http.post(apiUrl + 'update/', data);
  }

  deleteIncidentType(id: any) {
    return this.http.post(apiUrl + route + 'delete', id);
  }
}
