import { Pipe, PipeTransform } from '@angular/core';
import { LoggedInUser } from '@mrsoftebs/data';

@Pipe({
  name: 'isowner',
})
export class IsOwnerPipe implements PipeTransform {
  user: LoggedInUser = JSON.parse(localStorage.getItem('EBSloggedInUser'));

  transform(value, args?: any) {
    console.log('value: ', value);
    if (Array.isArray(value)) {
      if (!value.find((staff) => staff.StaffId === this.user.userId)) {
        return false;
      }
      return true;
    } else {
      return this.user.userId === value;
    }
  }
}
