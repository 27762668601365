import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PolicyDocumentCategory } from '@mrsoftebs/data';
import { Observable } from 'rxjs';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;
const route = 'policypocumentcategory/';

@Injectable({
  providedIn: 'root',
})
export class PolicyDocumentCategoryService {
  constructor(private http: HttpClient) {}

  getPolicyDocumentCategoryById(
    id: string
  ): Observable<PolicyDocumentCategory> {
    return this.http.get<PolicyDocumentCategory>(api + route + id);
  }
  getAllPolicyDocumentCategory(): Observable<PolicyDocumentCategory[]> {
    return this.http.get<PolicyDocumentCategory[]>(api + route);
  }

  createPolicyDocumentCategory(
    policypocumentcategory: PolicyDocumentCategory[]
  ) {
    return this.http.post(api + route, policypocumentcategory);
  }

  updatePolicyDocumentCategory(data) {
    return this.http.post(api + route + 'update', data);
  }

  deletePolicyDocumentCategory(id: any) {
    return this.http.post(api + route + 'delete', id);
  }
}
