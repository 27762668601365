import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InventoryStatusDto } from '@mrsoftebs/data';
import { environment } from '../../../../../apps/ebs/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InventoryStatusService {
  url = environment.apiUrl + 'reference/status/';
  constructor(private http: HttpClient) {}

  getInventoryStatus() {
    return this.http.get<InventoryStatusDto[]>(this.url);
  }
  createInventoryStatus(status: InventoryStatusDto[]) {
    return this.http.post(this.url, status);
  }

  updateInventoryStatus(status: InventoryStatusDto[]) {
    return this.http.post(this.url + 'update', status);
  }

  deleteInventoryStatus(ids: string[]) {
    return this.http.post(this.url + 'remove', ids);
  }
}
