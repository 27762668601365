import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Approval, Requisition } from '@mrsoftebs/data';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;
const route = 'Requisition/';
@Injectable({
  providedIn: 'root',
})
export class RequisitionService {
  constructor(private http: HttpClient) {}

  getRequisitionById(id): Observable<Requisition> {
    return this.http.get<Requisition>(api + route + id);
  }
  getApprovalyId(damageRefNo): Observable<Approval[]> {
    return this.http.get<Approval[]>(
      api + route + 'getApproval/' + damageRefNo
    );
  }
  getAllRequisition(data?): Observable<Requisition[]> {
    return this.http.get<Requisition[]>(api + route);
  }
  updateApprovalById(data) {
    return this.http.post(api + route + 'updateapproval', data);
  }
  // createRequisition(requisitions: Requisition[]) {
  //   //console.log('update create: ', relationshiptypes);
  //   return this.http.post(api + route, requisitions);
  // }
  createRequisition(requisitions) {
    return this.http.post(api + route, requisitions);
  }
  updateRequisition(data) {
    return this.http.post(api + route + 'update', data);
  }

  // updateApproval(data) {
  //   return this.http.post(api + route + 'updateapproval', data);
  // }

  public uploadFile(files: File[], data: any): Observable<HttpEvent<{}>> {
    const formData = new FormData();

    console.log('Files Passed ', files);

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        // formData.append(files[i].name, files[i]);

        if (files[i] !== undefined) {
          formData.append('file', files[i], files[i].name);
        }
      }
    }

    // return;
    // if (files.length > 1) {
    //   console.log('File Length', files.length);

    // } else {
    //   formData.append('file', files[0], files[0].name);
    // }

    console.log('Appended Files  ', formData);

    // formData.append('file', file, file.name);
    formData.append('Body', JSON.stringify(data));

    console.log('Reaching Service for Upload', formData);

    const options = {
      reportProgress: true,
    };

    const req = new HttpRequest(
      'POST',
      `${api + route + 'createdocument'}`,
      formData,
      options
    );

    return this.http.request(req);
  }

  deleteRequisition(id: any) {
    //console.log("deleted id: ", id);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: id,
    };

    // let params = new HttpParams();
    // params = params.append(route, id.join(', '));
    //return this.http.delete(api + route + id);
    return this.http.post(api + route+'delete', id);
  }
}
