import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KpiType } from '@mrsoftebs/data';
import { environment } from 'apps/ebs/src/environments/environment';
import { Observable } from 'rxjs';
const apiUrl = environment.apiUrl;
const route = 'kpitype/';

@Injectable({
  providedIn: 'root',
})
export class KpiTypeService {
  constructor(private http: HttpClient) {}

  getKpiTypeById(id): Observable<KpiType> {
    return this.http.get<KpiType>(apiUrl + route + id);
  }

  getAllKpiType(data?): Observable<KpiType[]> {
    return this.http.get<KpiType[]>(apiUrl + route);
  }

  createKpiType(title: KpiType[]) {
    return this.http.post(apiUrl + route, title);
  }

  updateKpiType(data) {
    return this.http.post(apiUrl + 'update/', data);
  }

  deleteKpiType(id: any) {
    return this.http.post(apiUrl + route + 'delete', id);
  }
}
