import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Priority } from '@mrsoftebs/data';
import { Observable } from 'rxjs';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;
const route = 'priority/';

@Injectable({
  providedIn: 'root',
})
export class PriorityService {
  constructor(private http: HttpClient) {}

  getPriorityById(id): Observable<Priority> {
    return this.http.get<Priority>(api + route + id);
  }
  getAllPriority(data?): Observable<Priority[]> {
    console.log('data: ', data);
    return this.http.get<Priority[]>(api + route);
  }

  createPriority(priority: Priority[]) {
    return this.http.post(api + route, priority);
  }

  updatePriority(data) {
    return this.http.post(api + 'priority/update', data);
  }

  deletePriority(id: any) {
    return this.http.post(api + route + 'delete', id);
  }
}
