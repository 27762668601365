import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WireSize } from '@mrsoftebs/data';
import { environment } from 'apps/ebs/src/environments/environment';
import { Observable } from 'rxjs';

const api = environment.apiUrl;
const route = 'wiresize/';

@Injectable({
  providedIn: 'root',
})
export class WireSizeService {
  constructor(private http: HttpClient) {}
  getWireSizeById(id): Observable<WireSize> {
    return this.http.get<WireSize>(api + route + id);
  }

  allWireSize(data?): Observable<WireSize[]> {
    return this.http.get<WireSize[]>(api + route + 'AllWireSize');
  }

  createCustomWireSize(wiresize: WireSize[]) {
    console.log('path', api + route + 'createWireSize');
    return this.http.post(api + route + 'createWireSize', wiresize);
  }

  updateWireSize(data) {
    console.log('dupdate', data);
    return this.http.post(api + route + 'updateWireSize', data);
  }
  deleteWireSize(ids: any) {
    // const httpOptions = {
    //   headers: new HttpHeaders({ 'Content-': 'application/json' }),
    //   body: id,
    // };
    // let params = new HttpParams();
    // params = params.append(route, id.join(', '));
    return this.http.post(api + route + 'delete', ids);
  }
}
