import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestType } from '@mrsoftebs/data';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;
const route = 'requesttype/';
const deleteroute='delete';

@Injectable({
  providedIn: 'root',
})
export class RequestTypeService {
  constructor(private http: HttpClient) {}

  getRequestTypeById(id): Observable<RequestType> {
    return this.http.get<RequestType>(api + route + id);
  }
  getAllRequestType(data?): Observable<RequestType[]> {
    return this.http.get<RequestType[]>(api + route);
  }

  createRequestType(requestTypes: RequestType[]) {
    return this.http.post(api + route, requestTypes);
  }

  updateRequestType(data) {
    return this.http.post(api + 'requesttype/update', data);
  }

  deleteRequestType(id: any) {
   
    return this.http.post(api + route + 'delete', id);
  }
}
