import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AppraiseQuestionaire } from '@mrsoftebs/data';
import { Observable } from 'rxjs';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;
const route = 'appraisequestionaire/';

@Injectable({
  providedIn: 'root',
})
export class AppraiseQuestionaireService {
  constructor(private http: HttpClient) {}

  getAppraiseQuestionaireById(id): Observable<AppraiseQuestionaire> {
    return this.http.get<AppraiseQuestionaire>(api + route + id);
  }
  getAllAppraiseQuestionaire(data?): Observable<AppraiseQuestionaire[]> {
    return this.http.get<AppraiseQuestionaire[]>(api + route);
  }

  createAppraiseQuestionaire(appraisequestionaire: AppraiseQuestionaire[]) {
    return this.http.post(api + route, appraisequestionaire);
  }

  updateAppraiseQuestionaire(data) {
    return this.http.post(api + 'appraisequestionaire/update', data);
  }

  deleteAppraiseQuestionaire(id: any) {
    return this.http.post(api + route + 'delete', id);
  }
}
