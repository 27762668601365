import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BidType } from '@mrsoftebs/data';
import { environment } from 'apps/ebs/src/environments/environment';

const url = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class BidTypeService {
  constructor(private http: HttpClient) {}

  getBidTypes() {
    return this.http.get<BidType[]>(url + 'bidtype');
  }
  createBidType(bid: BidType[]) {
    return this.http.post(url + 'bidtype/create', bid);
  }

  updateBidType(bid: BidType[]) {
    return this.http.post(url + 'bidtype/update', bid);
  }

  deleteBidType(ids: any) {
    console.log('ids', ids);
    return this.http.post(url + 'bidtype/delete', ids);
  }
}
