import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'apps/ebs/src/environments/environment';

import { Observable } from 'rxjs';
import { ContractType } from '@mrsoftebs/data';

const url = environment.apiUrl;
const route = 'contracttype/';

@Injectable({
  providedIn: 'root',
})
export class ContractTypeService {
  constructor(private http: HttpClient) {}

  getAllContractType(data?): Observable<ContractType[]> {
    return this.http.get<ContractType[]>(url + route);
  }

  getContractTypeById(id): Observable<ContractType[]> {
    return this.http.get<ContractType[]>(url + route + id);
  }

  createContractType(contracttype: ContractType[]) {
    return this.http.post(url + route, contracttype);
  }

  updateContractType(data) {
    console.log('update: ', data);
    return this.http.post(url + route + 'update', data);
  }

  deleteContractType(id: any) {
    return this.http.post(url + route + 'delete', id);
  }
}
