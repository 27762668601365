import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'apps/ebs/src/environments/environment';
import { Observable } from 'rxjs';
import { ContractStatus } from '@mrsoftebs/data';

const url = environment.apiUrl;
const route = 'contractstatus/';

@Injectable({
  providedIn: 'root',
})
export class ContractStatusService {
  constructor(private http: HttpClient) {}

  getAllContractStatus(data?): Observable<ContractStatus[]> {
    return this.http.get<ContractStatus[]>(url + route);
  }

  getContractStatusById(id): Observable<ContractStatus[]> {
    return this.http.get<ContractStatus[]>(url + route + id);
  }

  createContractStatus(contractstatus: ContractStatus[]) {
    return this.http.post(url + route, contractstatus);
  }

  updateContractStatus(data) {
    console.log('update: ', data);
    return this.http.post(url + route + 'update', data);
  }

  deleteContractStatus(id: any) {
    return this.http.post(url + route + 'delete', id);
  }
}
