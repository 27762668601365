import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/ebs/src/environments/environment';
import { Observable } from 'rxjs';

const route = 'bank/';
const api = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class BankService {
  constructor(private http: HttpClient) {}
  saveAll(banks: any) {
    return this.http.post(api + route, banks);
  }
  getAll(): Observable<any[]> {
    return this.http.get<any[]>(api + route);
  }
}
