import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/ebs/src/environments/environment';
import { Step } from '@mrsoftebs/data';
import { Observable } from 'rxjs';

const apiUrl = environment.apiUrl;
const route = 'step/';

@Injectable({ providedIn: 'root' })
export class StepService {
  constructor(private http: HttpClient) {}
  findAll(): Observable<Step[]> {
    return this.http.get<Step[]>(apiUrl + route);
  }
  getStepByLevelId(levelId: string): Observable<Step[]> {
    return this.http.get<Step[]>(apiUrl + 'Step/levelId/' + levelId);
  }
  findOne(id: string): Observable<any> {
    return this.http.get<any>(apiUrl + route + id);
  }
  updateMany(data) {
    return this.http.post(apiUrl + route, data);
  }

  deleteStep(id: any) {
    return this.http.post(apiUrl + route + 'delete', id);
  }
}
