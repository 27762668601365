import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { JobTitle } from '@mrsoftebs/data';
import { Observable } from 'rxjs';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;
const route = 'jobtitle/';

@Injectable({
  providedIn: 'root',
})
export class JobTitleService {
  constructor(private http: HttpClient) {}

  getJobTitleById(id): Observable<JobTitle> {
    return this.http.get<JobTitle>(api + route + id);
  }
  getAllJobTitle(data?): Observable<JobTitle[]> {
    return this.http.get<JobTitle[]>(api + route);
  }

  createJobTitle(jobtitle: JobTitle[]) {
    return this.http.post(api + route, jobtitle);
  }

  updateJobTitle(data) {
    return this.http.post(api + 'jobtitle/update', data);
  }

  deleteJobTitle(id: any) {
    return this.http.post(api + route + 'delete', id);
  }

  getJobtitleByUnitId(unitId: string) {
    return this.http.get<JobTitle[]>(api + 'jobtitle/unit/' + unitId);
  }
}
