import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnitOfMeasureDto } from '@mrsoftebs/data';
import { environment } from '../../../../../apps/ebs/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MeasureService {
  url = environment.apiUrl + 'reference/measure/';
  constructor(private http: HttpClient) {}

  getMeasure() {
    return this.http.get<UnitOfMeasureDto[]>(this.url);
  }
  createMeasure(measure: UnitOfMeasureDto[]) {
    return this.http.post(this.url, measure);
  }

  updateMeasure(measure: UnitOfMeasureDto[]) {
    return this.http.post(this.url + 'update', measure);
  }

  deleteMeasure(ids: string[]) {
    return this.http.post(this.url + 'remove', ids);
  }
}
