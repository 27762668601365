import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SupplierDto } from '@mrsoftebs/data';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from '../../../../../apps/ebs/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  url = environment.apiUrl + 'reference/supplier/';
  constructor(private http: HttpClient) {}

  getSupplier() {
    return this.http.get<SupplierDto[]>(this.url);
  }
  createSupplier(status: SupplierDto[]) {
    return this.http.post(this.url, status);
  }

  updateSupplier(status: SupplierDto[]) {
    return this.http.post(this.url + 'update', status);
  }

  deleteSupplier(ids: string[]) {
    return this.http.post(this.url + 'remove', ids);
  }
}
