import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsOwnerPipe } from './pipes/is-owner.pipe';
import { DisplayNamePipe } from './pipes/display-name.pipe';
import { TimePipe } from './pipes/time.pipe';

@NgModule({
  declarations: [IsOwnerPipe, DisplayNamePipe, TimePipe],
  imports: [CommonModule],
  exports: [IsOwnerPipe, DisplayNamePipe, TimePipe],
})
export class PipesModule {}
