import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TypeOfWire } from '@mrsoftebs/data';
import { environment } from 'apps/ebs/src/environments/environment';
import { Observable } from 'rxjs';

const api = environment.apiUrl;
const route = 'typeofwire/';

@Injectable({
  providedIn: 'root',
})
export class TypeOfWireService {
  constructor(private http: HttpClient) {}
  getTypeOfWireById(id): Observable<TypeOfWire> {
    return this.http.get<TypeOfWire>(api + route + id);
  }

  allTypeOfWire(data?): Observable<TypeOfWire[]> {
    return this.http.get<TypeOfWire[]>(api + route + 'AllTypeOfWire');
  }

  createCustomTypeOfWire(typeofwire: TypeOfWire[]) {
    console.log('path', api + route + 'createTypeOfWire');
    return this.http.post(api + route + 'createTypeOfWire', typeofwire);
  }

  updateTypeOfWire(data) {
    console.log('dupdate', data);
    return this.http.post(api + route + 'updateTypeOfWire', data);
  }
  deleteTypeOfWire(ids: any) {
    // const httpOptions = {
    //   headers: new HttpHeaders({ 'Content-': 'application/json' }),
    //   body: id,
    // };
    // let params = new HttpParams();
    // params = params.append(route, id.join(', '));
    return this.http.post(api + route + 'delete', ids);
  }
}
