import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SampleParameter } from '@mrsoftebs/data';
import { environment } from 'apps/ebs/src/environments/environment';

import { Observable } from 'rxjs';

const api = environment.apiUrl;
const route = 'sampleparameter/';

@Injectable({
  providedIn: 'root',
})
export class SampleParameterService {
  constructor(private http: HttpClient) {}
  getSampleParameterById(id): Observable<SampleParameter> {
    return this.http.get<SampleParameter>(api + route + id);
  }

  allSampleParameter(data?): Observable<SampleParameter[]> {
    return this.http.get<SampleParameter[]>(api + route + 'AllSampleParameter');
  }

  createCustomSampleParameter(sampleparameter: SampleParameter[]) {
    console.log('path', api + route + 'createSampleParameter');
    return this.http.post(
      api + route + 'createSampleParameter',
      sampleparameter
    );
  }

  updateSampleParameter(data) {
    console.log('dupdate', data);
    return this.http.post(api + route + 'updateSampleParameter', data);
  }
  deleteSampleParameter(ids: any) {
    // const httpOptions = {
    //   headers: new HttpHeaders({ 'Content-': 'application/json' }),
    //   body: id,
    // };
    // let params = new HttpParams();
    // params = params.append(route, id.join(', '));
    return this.http.post(api + route + 'delete', ids);
  }
}
