import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { BankBranch } from '@mrsoftebs/data';
import { Observable } from 'rxjs';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;
const route = 'bankbranch/';

@Injectable({
  providedIn: 'root',
})
export class BankBranchService {
  constructor(private http: HttpClient) {}

  getBankBranchById(id): Observable<BankBranch> {
    return this.http.get<BankBranch>(api + route + id);
  }
  getAllBankBranch(data?): Observable<BankBranch[]> {
    console.log('data: ', data);
    return this.http.get<BankBranch[]>(api + route);
  }

  createBankBranch(bankbranch: BankBranch[]) {
    return this.http.post(api + route, bankbranch);
  }

  updateBankBranch(data) {
    return this.http.post(api + 'bankbranch/update', data);
  }

  deleteBankBranch(id: any) {
    return this.http.post(api + route + 'delete', id);
  }
}
